import './App.css';
import {withRouter} from 'react-router-dom';


function App() {
  console.log("a");
  var a=(window.location.pathname);
  var buttonUrl=("https://v.lentorambulance.com/sms"+window.location.pathname);
  return (
    <div className="App">
      <header className="App-header">
        <h1>Lentor Mobile Number Verification</h1>
        <p>Reference ID:{a}</p>
        <br>
        </br>
        <a
          className="App-link"
          href={buttonUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click Me to Verify
        </a><br></br>
        <p>If you are not a staff or have any questions, kindly contact Nicholas at nicholasc@lentorambulance.com</p>
      </header>
    </div>
  );

}

export default App;
